.tf-tree {
  font-size: 16px;
  overflow: auto;
}

.tf-tree * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.tf-tree ul {
  display: inline-flex;
}

.tf-tree li {
  flex-flow: column wrap;
  align-items: center;
  padding: 0 1em;
  display: flex;
  position: relative;
}

.tf-tree li ul {
  margin: 2em 0;
}

.tf-tree li li:before {
  content: "";
  border-top: .0625em solid #000;
  width: 100%;
  height: .0625em;
  display: block;
  position: absolute;
  top: -1.03125em;
  left: -.03125em;
}

.tf-tree li li:first-child:before {
  max-width: calc(50% + .0625em);
  left: calc(50% - .03125em);
}

.tf-tree li li:last-child:before {
  max-width: calc(50% + .0625em);
  left: auto;
  right: calc(50% - .03125em);
}

.tf-tree li li:only-child:before {
  display: none;
}

.tf-tree li li:only-child > .tf-nc:before, .tf-tree li li:only-child > .tf-node-content:before {
  height: 1.0625em;
  top: -1.0625em;
}

.tf-tree .tf-nc, .tf-tree .tf-node-content {
  border: .0625em solid #000;
  padding: .5em 1em;
  display: inline-block;
  position: relative;
}

.tf-tree .tf-nc:before, .tf-tree .tf-node-content:before {
  top: -1.03125em;
}

.tf-tree .tf-nc:after, .tf-tree .tf-nc:before, .tf-tree .tf-node-content:after, .tf-tree .tf-node-content:before {
  content: "";
  border-left: .0625em solid #000;
  width: .0625em;
  height: 1em;
  display: block;
  position: absolute;
  left: calc(50% - .03125em);
}

.tf-tree .tf-nc:after, .tf-tree .tf-node-content:after {
  top: calc(100% + .03125em);
}

.tf-tree .tf-nc:only-child:after, .tf-tree .tf-node-content:only-child:after, .tf-tree > ul > li > .tf-nc:before, .tf-tree > ul > li > .tf-node-content:before {
  display: none;
}

.tf-tree.tf-gap-sm li {
  padding: 0 .6em;
}

.tf-tree.tf-gap-sm li > .tf-nc:before, .tf-tree.tf-gap-sm li > .tf-node-content:before {
  height: .6em;
  top: -.6em;
}

.tf-tree.tf-gap-sm li > .tf-nc:after, .tf-tree.tf-gap-sm li > .tf-node-content:after {
  height: .6em;
}

.tf-tree.tf-gap-sm li ul {
  margin: 1.2em 0;
}

.tf-tree.tf-gap-sm li li:before {
  top: -.63125em;
}

.tf-tree.tf-gap-sm li li:only-child > .tf-nc:before, .tf-tree.tf-gap-sm li li:only-child > .tf-node-content:before {
  height: .6625em;
  top: -.6625em;
}

.tf-tree.tf-gap-lg li {
  padding: 0 1.5em;
}

.tf-tree.tf-gap-lg li > .tf-nc:before, .tf-tree.tf-gap-lg li > .tf-node-content:before {
  height: 1.5em;
  top: -1.5em;
}

.tf-tree.tf-gap-lg li > .tf-nc:after, .tf-tree.tf-gap-lg li > .tf-node-content:after {
  height: 1.5em;
}

.tf-tree.tf-gap-lg li ul {
  margin: 3em 0;
}

.tf-tree.tf-gap-lg li li:before {
  top: -1.53125em;
}

.tf-tree.tf-gap-lg li li:only-child > .tf-nc:before, .tf-tree.tf-gap-lg li li:only-child > .tf-node-content:before {
  height: 1.5625em;
  top: -1.5625em;
}

.tf-tree li.tf-dotted-children .tf-nc:after, .tf-tree li.tf-dotted-children .tf-nc:before, .tf-tree li.tf-dotted-children .tf-node-content:after, .tf-tree li.tf-dotted-children .tf-node-content:before {
  border-left-style: dotted;
}

.tf-tree li.tf-dotted-children li:before {
  border-top-style: dotted;
}

.tf-tree li.tf-dotted-children > .tf-nc:before, .tf-tree li.tf-dotted-children > .tf-node-content:before {
  border-left-style: solid;
}

.tf-tree li.tf-dashed-children .tf-nc:after, .tf-tree li.tf-dashed-children .tf-nc:before, .tf-tree li.tf-dashed-children .tf-node-content:after, .tf-tree li.tf-dashed-children .tf-node-content:before {
  border-left-style: dashed;
}

.tf-tree li.tf-dashed-children li:before {
  border-top-style: dashed;
}

.tf-tree li.tf-dashed-children > .tf-nc:before, .tf-tree li.tf-dashed-children > .tf-node-content:before {
  border-left-style: solid;
}
/*# sourceMappingURL=index.a761a623.css.map */
